import { createAsyncThunk } from "@reduxjs/toolkit";
import instance from "../../Apis/axios-config";
import { type } from "@testing-library/user-event/dist/type";

export const signUpThunk = createAsyncThunk(
    "signUpThunk",
    async function({name,email,category,governing,userSocketID}){
        try {
            const response = await instance.post("/user/register",{
                name,
                email,
                message_category_id: category,
                governing_body: governing,
                user_device : "YYY",
                type : "IOS",
                phone_number: "077777777",
                socket_id:userSocketID         
            })

            console.log("response-data------",response.data);
            
            
            return response.data
        } catch (error) {
            return error?.response?.data || error || "Unknown Error"
        }
    }
)

export const signInThunk = createAsyncThunk(
    "signInThunk",
    async function({nickname,socketID}){
        try {
     
            const response = await instance.post("/api/auth/signIn",{
                nickname, 
                socketID,
                phoneID : "YYY"      
            })
            
            console.log("res---",response.data);
            return response.data
        } catch (error) {
            return error?.response?.data || error || "Unknown Error"
        }
    }
)

export const signOutThunk = createAsyncThunk(
    "signOutThunk",
   async function ({userId}){
    try {
        const response = await instance.post("/api/auth/signOut",{
           userId      
        })
        
        return response.data
    } catch (error) {
        return error?.response?.data || error || "Unknown Error"
    }
   }
)






export const sendMessageThunk = createAsyncThunk(
    "sendMessageThunk",
    async function({room_id,writer_id,content,writer},{rejectWithValue}){
        try {
            const data = await instance.post("message/create",{room_id,writer_id,content,writer})

            return data.data

        } catch (error) {
            console.error(error)
        }
    }
)