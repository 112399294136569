
import axios from "axios";


const instance = axios.create({
    baseURL : "https://citizenb.trigger.ltd/api/",
    headers: {
        authorization : `Bearer `,
        "Accept-Language ": "am"
    },
})

export default instance