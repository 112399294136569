import { createAsyncThunk } from '@reduxjs/toolkit'
import instance from '../../Apis/axios-config.js' 

export const searchUserThunk = createAsyncThunk(
    "searchUserThunk",
    async function({maxAge, minAge, gender,userId,socketID}){
        try {
       

            const response = await instance.get(`/api/user/search/${userId}/?socketID=${socketID}&gender=${gender}&maxAge=${maxAge}&minAge=${minAge}`)
            
            return response.data
        } catch (error) {
            return error?.response?.data || error || "Unknown Error"
        }
    }
)
export const addChatThunk = createAsyncThunk(
    "addChatThunk",
    async function({roomId,chat,userId,participantId,save}){
        try {
           
            console.log("thunk--",roomId,chat,userId);
            const response = await instance.post(`/api/user/addChat`,{
                roomId,chat,userId,participantId,save
            })
            
            return response.data
        } catch (error) {
            return error?.response?.data || error || "Unknown Error"
        }
    }
)

export const updateMessageSituationThunk = createAsyncThunk(
    "updateMessageSituationThunk",
    async function({message_id}){
        try {
            const response = await instance.post("message/update/situation",{message_id})
            return response.data
        } catch (error) {
            console.error(error)
        }
    }
)




