
import React, { useEffect, useState } from "react";
import io from "socket.io-client";
import "./App.css";
import SignUp from "./Components/SignIn/SignUp";
import { setRoom, setSocketID } from "./Redux/Slices/Auth/AuthSlice.js";
import { useDispatch, useSelector } from "react-redux";
import { Route, Routes, useNavigate } from "react-router-dom";
import Chat from "./Components/Chat/Chat.jsx";


const socket = io('https://citizenb.trigger.ltd');


function App() {

const dispatch = useDispatch()
const navigate = useNavigate()

const {userSocketID,userInfo}  = useSelector((state)=> state.auth.user)

  useEffect(() => {
    socket.on("connect", (data) => {
      console.log("Connected to the server",socket.id);
      dispatch(setSocketID(socket.id))
    });
   

  }, []);



  return (
    <div>
      {/* <Nav socket={socket}/> */}
      <h1>{userSocketID}</h1>

       <Routes>
          <Route path="/" element={ <SignUp socket={socket}/>}/>
          <Route path="/chat" element={<Chat socket={socket}/>}/>
       </Routes> 

    </div>
  );
}

export default App;
