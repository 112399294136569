import { createSlice } from "@reduxjs/toolkit"
import { searchUserThunk } from "./chatThunk"



const initialState = {
    socketID : null,
    participant: null,
    myCurrentRoom : "",
    loading_status : "pending"
}

const chatSlice = createSlice({
    name: "chatSlice",
    initialState,
    reducers: {
        // setSocketID(state,action){
        //     state.socketID = action.payload
        // },
        setMyCurrentRoom(state,{payload}){
            state.myCurrentRoom = payload
        },
        setParticipant(state,action){
            state.participant = null
        }
    },
    extraReducers: (builder)=>{
        builder
        .addCase(searchUserThunk.pending,(state,{payload})=>{
            state.loading_status = "pending"
            console.log("pending");
        })
        .addCase(searchUserThunk.fulfilled,(state,{payload})=>{
            state.loading_status = "fulfilled"
            state.participant = payload.user
        })
        .addCase(searchUserThunk.rejected,(state,{payload})=>{
            state.loading_status = "rejected"
        })
    }
})

export const {setSocketID,setMyCurrentRoom,setParticipant} = chatSlice.actions

export const chatReducer = chatSlice.reducer