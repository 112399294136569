export const ACCESS_TOKEN = "accesToken"
export const REFRESH_TOKEN = "refreshToken"


export function signOut(){
    removeAuthToken()
    window.location.href = '/'
}

export function getAccessToken(){
    return localStorage.getItem(ACCESS_TOKEN)
}


export function setAuthToken(accessToken){
    localStorage.setItem(ACCESS_TOKEN,accessToken)
    return
}

export function removeAuthToken(){
    localStorage.removeItem(ACCESS_TOKEN)
}