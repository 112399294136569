import { configureStore } from "@reduxjs/toolkit";
import { chatReducer } from "./Slices/Chat/ChatSlice";
import AuthSlice from "./Slices/Auth/AuthSlice";



const store = configureStore({
    reducer: {
        chatSlice : chatReducer,
        auth : AuthSlice,
    }
})

export default store