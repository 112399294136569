import React from 'react'
import { useNavigate } from 'react-router-dom'
import { getAccessToken } from '../Utils/accountUtils'

const requireAuth = (WrappedComponent) => {
  return (props)=>{
    const navigate = useNavigate()
    const token = getAccessToken()

    if(!token){
        window.location.href = "/user/signUp"
    }else{
        return <WrappedComponent {...props}/>
    }
  }
}

export default requireAuth