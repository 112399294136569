import React, { useEffect, useState } from 'react'
import './chat.css'
import requireAuth from '../../HOC/requireAuth';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setRoom } from '../../Redux/Slices/Auth/AuthSlice';
import { sendMessageThunk } from '../../Redux/Slices/Auth/authThunk';
import { updateMessageSituationThunk } from '../../Redux/Slices/Chat/chatThunk';






const Chat = ({socket}) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    let [text, setText] = useState("")
    let [messages, setMessages] = useState([])
    let {rooms,userInfo} = useSelector((state)=> state.auth.user)
 
    useEffect(() => {
      socket.on("receive_message", (data) => {
        setMessages((prev)=>{
          return [...prev,data]
        })
        console.log("receive-message-----",data);
      });

     
      socket.on("messageReadUser", (data)=>{

        console.log("messageReadUser---",data);
      })

     

  
      return () => {
        socket.off("receiveMessage");
        socket.off("messageReadUser");
      };
    }, []);

    console.log("my state---",messages);

    useEffect(()=>{
      socket.on("userMessageAlreadyReaded",(data)=>{
        console.log("userMessageAlreadyReaded----",data);
      })
    },[])

    useEffect(()=>{
      socket.on("roomCreated",(data)=>{
        dispatch(setRoom(data.room))
        
      })
    },[])



    async function sendMessage(){
      if(text.length){
        console.log("send message---",{room_id :rooms,writer_id:userInfo.id,content:text,writer: "user"});
        const sendData = await dispatch(sendMessageThunk({room_id :rooms,writer_id:userInfo.id,content:text,writer: "user"}))
        console.log("will_send_message---",sendData);
        socket.emit("create_message",sendData.payload.message)
        setText("")
      }
    }

    async function readOperatorMessages(){
      console.log("read-----",messages);
      socket.emit("markMessageAsReadUser", {messages,id: rooms});
      socket.emit("operatorMessageWasReaded",{id:rooms})
      messages.forEach((el)=>{
        if(el.writer === "operator"){
          dispatch(updateMessageSituationThunk({message_id: el.id}))
        }
      })
    }


  return (
    <div>
         <div className="chat">
            <div className="text">
                {
                  
                  messages.length ? messages.map((el,i)=><div key={i}>{el.content}</div>) : ""
                }

    
          
            </div>
            <div className="send_area">
            
                <input type="text"  value={text} onChange={(e)=>setText(e.target.value)}/>
                <button onClick={readOperatorMessages}>Read</button>
                <div className="send" onClick={()=>sendMessage()}><i className="fa-solid fa-paper-plane"></i></div>
          
            </div>
         </div>


    </div>
  )
}

export default React.memo(requireAuth(Chat))


