import React, { useEffect, useState } from 'react'
import './signIn_signUp.css'
import { signUpThunk } from '../../Redux/Slices/Auth/authThunk'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { setAuthToken } from '../../Utils/accountUtils'
import { setRoom } from '../../Redux/Slices/Auth/AuthSlice'

const SignUp = ({socket}) => {
  let [category, setCategory] = useState("")
  let [name, setName] = useState("")
  let [email, setEmail] = useState("")
  let [governing, setGoverning] = useState("")

  const {userSocketID,userInfo} = useSelector((state)=> state.auth.user)



  const navigate = useNavigate()
  const dispatch = useDispatch()


  async function register(){
    if(category && name && email && governing){
      const data = await dispatch(signUpThunk({name,email,category,governing,userSocketID}))
      setAuthToken(data.payload)
      console.log("payload------",data.payload);
      socket.emit("searchAdmin",data.payload)
      navigate("/chat")
    }
  }



  return (
    <div>
        <div className="signUp">
            <div className="gender">
                <div className="male">
                    <input type="text" value={name} placeholder='Name' onChange={(e)=>setName(e.target.value)}/>
                </div>
            </div>
            <div className="age">
            <input type="text" value={email} placeholder='Email' onChange={(e)=>setEmail(e.target.value)}/>

            </div>
            <div className="nickname">
            <input type="text" value={category} placeholder='Category' onChange={(e)=>setCategory(e.target.value)}/>
            <input type="text" value={governing} placeholder='Hastatutyun' onChange={(e)=>setGoverning(e.target.value)}/>

            </div>
            <button onClick={()=>register()}>REGISTER</button>
        </div>
    </div>
  )
}

export default SignUp