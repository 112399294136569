import { createSlice } from "@reduxjs/toolkit"
import { signInThunk, signUpThunk } from "./authThunk"
import { jwtDecode } from "jwt-decode"


const initialState = {
    user : {
        userInfo: null,
        userSocketID : null,
        message : "",
        access_token : null,
        rooms : null
    },
    loadingStatus : "pending"
}



const AuthSlice = createSlice({
    name: "AuthSlice",
    initialState,
    reducers:{
        setSocketID(state,action){
            state.user.userSocketID = action.payload
        },
        setRoom(state,action){
            state.user.rooms = action.payload
        }
    },
    extraReducers: (builder)=>{
        builder
        .addCase(signUpThunk.pending, (state,action)=>{
            state.loadingStatus = "pending"
        })
        .addCase(signUpThunk.fulfilled, (state,action)=>{
            console.log("thunk-----",action.payload);
            state.loadingStatus = "fulfilled"
            state.user.userInfo  = action.payload
        })
        .addCase(signUpThunk.rejected, (state,action)=>{
            state.loadingStatus = "rejected"
        })        
    }
})

export const {setSocketID,setRoom}  = AuthSlice.actions

export default AuthSlice.reducer